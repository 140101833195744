<template>
  <validation-observer
    ref="observer"
    rules="required"
    v-slot="{ handleSubmit }"
  >
    <RightSideBar
      v-if="openModal"
      @submit="handleSubmit(onSubmit)"
      :submit="id ? 'Update' : 'Save'"
      :loading="loading"
      @close="$emit('close')"
      :button-class="`bg-dynamicBackBtn text-white`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          {{ id ? "Edit Location" : "Add New Location" }}
        </p>
      </template>
      <p class="font-semibold text-darkPurple">
        {{ id ? "Edit" : "Add a new" }} location where your organization office
        can be found.
      </p>
      <div
        class="grid grid-cols-12 gap-4 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-12">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Location Name"
            v-model="location.name"
            :rules="['required']"
          />
        </div>
        <div class="col-span-12">
          <c-select
            placeholder="--Select--"
            :options="countries"
            variant="w-full"
            label="Country"
            v-model="location.country"
            :rules="['required']"
          />
        </div>
        <div class="col-span-12">
          <c-select
            placeholder="--Select--"
            :options="states"
            variant="w-full"
            label="State"
            v-model="location.stateId"
            v-if="location.country === 'NGA'"
            :rules="['required']"
          />
          <c-text
            placeholder="--Enter--"
            v-else
            label="State"
            variant="w-full h-10"
            v-model="location.state"
            :rules="['required']"
          />
        </div>
        <div class="col-span-12">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="City"
            v-model="location.city"
            :rules="['required']"
          />
        </div>
        <div class="col-span-12">
          <label class="customLabel">Address</label>
          <div>
            <gmap-autocomplete
              @change="setPlace"
              :rules="[(v) => !!v || 'Address is Required']"
              class="col-span-12"
              @place_changed="setPlace"
              ref="autocomplete"
              :value="location.address"
              placeholder="----Enter----"
            >
            </gmap-autocomplete>
          </div>
          <small v-show="showError" class="text-flame"
            >Please type & select from the map options</small
          >
        </div>
        <div class="col-span-12 mb-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Zip code"
            v-model="location.zipcode"
          />
        </div>
      </div>
    </RightSideBar>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import states from "@/helpers/states";
import countries from "@/helpers/countries";
import RightSideBar from "@/components/RightSideBar";

export default {
  namd: "AddLocation",
  components: {
    ValidationObserver,
    RightSideBar,
    CText,
    CSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      disableBtn: false,
      states,
      countries,
      loading: false,
      showError:'',
      location: {
        name: "",
        address: "",
        city: "",
        stateId: null,
        state: "",
        country: null,
        zipcode: "",
        longitude: "",
        latitude: "",
        isForOffice: true,
      },
    };
  },
  methods: {
    setPlace(place) {
      this.location.zipcode = ''
      if (!place.type) {
        this.showError = false;
        const match = place.formatted_address.match(/\b\d{6}\b/);
        this.location.address = place.name;
        this.location.latitude = place.geometry.location.lat();
        this.location.longitude = place.geometry.location.lng();
        this.location.zipcode = match ? match[0] : ''
      } else this.showError = true;
    },
    async onSubmit() {
      this.disableBtn = true;

      if (this.location.stateId) {
        const selectedState = this.states.find(
          (state) => state.id === this.location.stateId
        );
        this.location.state = selectedState?.name;
      }

      const payload = {
        ...this.location,
      };

      if (this.id) {
        this.$_updateOutlet(payload)
          .then(() => {
            this.$emit("close");
            this.$toasted.success("location updated successfully", {
              duration: 3000,
            });
            this.disableBtn = false;
            Object.assign(this.$data.location, this.$options.data().location);
          })
          .catch((error) => {
            this.$toasted.error("location not updated", { duration: 3000 });
            this.disableBtn = false;
            throw new Error(error);
          });
      } else {
        this.$_addOutlet(payload)
          .then(() => {
            this.$emit("close");
            this.$toasted.success("location added successfully", {
              duration: 3000,
            });
            this.disableBtn = false;
            Object.assign(this.$data.location, this.$options.data().location);
          })
          .catch((error) => {
            this.$toasted.error("location not added successfully", {
              duration: 3000,
            });
            this.disableBtn = false;
            throw new Error(error);
          });
      }
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.loading = true;
        this.$_getOneHumanarLocations(this.id).then((result) => {
          this.location = result.data.outlet;
          this.loading = false;
        });
      }

      Object.assign(this.$data.location, this.$options.data().location);
    },
  },
};
</script>
<style scoped>
.customLabel {
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333333;
  margin-bottom: 7px;
  font-family: "Nunito Sans";
}
input {
  border: 1px solid #878e99;
  height: 40px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}
input:hover {
  border: 1px solid #878e99;
}
input:focus-visible {
  border: 1px solid #878e99;
  outline: none;
}
input::placeholder {
  opacity: 1;
}
</style>
